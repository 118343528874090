import { Box, Button, IconButton, Typography, useMedia } from '@drivekyte/ui';
import { BookNowCTAProps } from './types';
import useSearch from '@/hooks/use-search';
import React, { useEffect, useState } from 'react';
import { Close } from '@drivekyte/icons';

const BookDiscountedRideCta = ({ data }: BookNowCTAProps) => {
  const [isCTABannerVisible, setIsCTABannerVisible] = useState(true);
  const media = useMedia();
  const { isFormValid, onSubmitWithQuotes, onChangeCouponCode } = useSearch();

  useEffect(() => {
    if (!data.discountCode) {
      return;
    }
    onChangeCouponCode(data.discountCode);
  }, []);

  const openCarpicker = () => {
    if (!isFormValid) {
      // throw maybe error
      return;
    }

    void onSubmitWithQuotes();
  };

  const handleCloseCTABanner = () => {
    setIsCTABannerVisible(false);
  };

  if (!isCTABannerVisible) {
    return null;
  }

  return (
    <Box $sm={{ padding: '$small' }} paddingVertical="$medium">
      <Box
        padding="$xLarge"
        borderRadius={24}
        backgroundColor="#064916"
        alignContent="space-between"
        justifyContent="space-between"
        $gtSm={{ flexDirection: 'row' }}
        $sm={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        maxWidth={1280}
        alignSelf="center"
        width="100%"
      >
        <Box
          flex={1}
          marginRight="$medium"
          flexDirection="column"
          justifyContent="center"
          $sm={{ marginRight: '$none' }}
        >
          <Typography
            textAlign="left"
            marginBottom="$small"
            variant="headingLarge"
            color="$contentInversePrimary"
            $sm={{ textAlign: 'center', marginBottom: '$none' }}
          >
            {data.headline}
          </Typography>
          {!media.gtSm && (
            <Box
              alignContent="center"
              justifyContent="center"
              marginVertical="$medium"
            >
              <Typography textAlign="center" color="$contentInversePrimary">
                {data.subtitle}
              </Typography>
            </Box>
          )}
          <Box
            alignItems="center"
            flexDirection="row"
            alignContent="center"
            justifyContent="flex-start"
            $sm={{ justifyContent: 'center' }}
          >
            {media.gtSm && (
              <Typography color="$contentInversePrimary">
                {data.subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Button
            size="large"
            variant="tertiary"
            onPress={openCarpicker}
            $sm={{ flex: 1, size: 'medium' }}
            cursor="pointer"
          >
            {data.cta}
          </Button>
          <Box display="flex" justifyContent="center" paddingLeft="$small">
            <IconButton
              cursor="pointer"
              size="small"
              icon={Close}
              onPress={handleCloseCTABanner}
            ></IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookDiscountedRideCta;
